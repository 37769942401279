.btn-mine {
    background: #0d1f2d;
    padding: 20px;
    border-radius: 10px;
    margin: 0 10px
}
.btn-mine:active{
    background: #0d1f2d;
}
btn-mine:hover{
    background: #546a7b;
}
btn-mine:focus{
    background: #0d1f2d;
}