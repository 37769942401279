.profile-modal.right .profile-modal-dialog {
    position: fixed;
    margin: auto;
    width: 420px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0)
}

.profile-modal.right .profile-modal-content {
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
    border: none;
    border-radius: 0
}

.profile-modal.right .profile-modal-content button.close {
    position: absolute;
    right: 20px;
    top: 30px;
    background-color: transparent;
    border: none;
    text-shadow: unset;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    z-index: 99;
    opacity: 1
}

.profile-modal.right .profile-modal-content button.close i {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    background-color: #4c5a7d;
    color: #fff;
    font-size: 20px;
    -webkit-transition: all ease .5s;
    transition: all ease .5s;
    border-radius: 50%
}

.profile-modal.right .profile-modal-content button.close i:hover {
    background-color: #111d5e
}

.profile-modal.right.fade .profile-modal-dialog {
    right: -320px;
    -webkit-transition: opacity .3s linear, right .3s ease-out;
    transition: opacity .3s linear, right .3s ease-out
}

.profile-modal.right.fade.show .profile-modal-dialog {
    right: 0
}

.sidebarModal.profile-modal .profile-modal-body {
    padding: 25px
}

.sidebarModal.profile-modal .profile-modal-body .white-logo {
    display: none
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-content {
    margin-top: 35px;
    margin-bottom: 40px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-content p {
    margin-bottom: 40px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-content h3 {
    font-size: 22px;
    margin-bottom: 15px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-content .sidebar-btn {
    margin-top: 25px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info {
    margin-top: 30px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info h3 {
    font-size: 22px;
    margin-bottom: 0
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list {
    padding-left: 0;
    margin-top: 30px;
    margin-bottom: 0
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list li {
    list-style-type: none;
    font-size: 14px;
    color: #555;
    font-weight: 500;
    margin-bottom: 20px;
    position: relative;
    padding-left: 22px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list li:last-child {
    margin-bottom: 0
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list li a {
    color: #555
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list li a:hover {
    color: #4c5a7d
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-contact-info .info-list li i {
    position: absolute;
    left: 0;
    top: 2px;
    color: #4c5a7d
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-social-list {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 30px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-social-list li {
    display: inline-block;
    margin-right: 8px
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-social-list li:last-child {
    margin-right: 0
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-social-list li i {
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 40px;
    font-size: 16px;
    background: #4c5a7d;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    -webkit-transition: all ease .5s;
    transition: all ease .5s
}

.sidebarModal.profile-modal .profile-modal-body .sidebar-social-list li i:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: #111d5e;
    color: #fff
}

.sidebarModal.profile-modal .profile-modal-body .contact-form {
    margin-top: 30px
}

.sidebarModal.profile-modal .profile-modal-body .contact-form h3 {
    font-size: 22px;
    margin-bottom: 25px
}

.sidebarModal.profile-modal .profile-modal-body .contact-form #contactForm .form-group {
    margin-bottom: 20px
}

.sidebarModal.profile-modal .profile-modal-body .contact-form #contactForm .form-group .form-control {
    padding: 15px
}

.sidebarModal.profile-modal .profile-modal-body .contact-form #contactForm .default-btn {
    border: none
}

.sidebarModal.profile-modal .profile-modal-body .contact-form #contactForm .list-unstyled {
    color: #ee1010;
    font-size: 14px;
    margin-top: 8px
}

.sidebarModal.profile-modal .profile-modal-body .contact-form #contactForm #msgSubmit {
    font-size: 15px
}