@media print {
    .text-size{
        font-size: 10px !important;
    }
    #printPageButton {
        display: none;
    }

    .hideSection {
        display: none;
    }

    .hideFooter {
        display: none !important;
    }
    .pagebreak {
        page-break-before: always;
    }
}

