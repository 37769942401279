
.leadership-modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    padding-top: 100px; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
  }
  
  .leadership-close {
    color: #192020;
    float: right;
    font-size: 18px;
  }
  
  .leadership-close:hover,
  .leadership-close:focus {
    color: rgb(255, 251, 251);
    text-decoration: none;
    cursor: pointer;
  }

.leadership-modal-header {
    padding: 2px 16px;
    background-color: #2d2c63e1;
    color: white;
  }
  
  .leadership-modal-body {
    padding: 2px 16px;
    text-align: justify;
}
  
  .leadership-modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }
  
  .leadership-modal-content {
    top:10%;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  @keyframes animatetop {
    from {top: -300px; opacity: 0}
    to {top: 10%; opacity: 1}
  }
