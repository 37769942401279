



/****************/
/*   BX-SLIDER  */
/****************/
section.client {
    padding: 4em 0em;
    background-color: #eee;

}

section.client .section-title {
    margin-bottom: 6em;
}

.bx-controls {
    position: relative;
}

.bx-wrapper .bx-pager {
    text-align: center;
    padding-top: 30px;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
    background: #666;
    text-indent: -9999px;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    outline: 0;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
}