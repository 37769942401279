.bg-text { overflow:hidden;
    text-shadow: 0px 0px 35px black,0px 0px 35px black,
    0px 0px 35px black,0px 0px 35px black;
}

.item-title:after {
    zoom: 1;
    content: "";
    display: table;
    clear: both;
    margin-bottom: 30px;
}

.title-divider {
    overflow: hidden;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-top: 0.6em;
}

#div_divider{
    font-size: 14px;
    border-bottom-width: 3px;
    width: 600px;
    margin-top: 10px;
    margin-left: 5px;
    color: #cccccc;
}

.column {
    float: left;
}

.main {
    transform: translate3d(-3540px, 0px, 0px); transition: all 0s ease 0s; width: 5310px;
}

.sub-main {
    width: 324px; margin-right: 30px;
}